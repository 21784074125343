export const staticResourceConfig = {
  Account: {
    uriPath: '/cc/API/Account',
  },
  AccountBackupIOConfig: {
    uriPath: '/cc/API/InsertionOrder/AccountBackupIOConfig',
    primaryKey: 'AccountId',
  },
  AccountBudgetCenterInformation: {
    uriPath: '/cc/API/BudgetCenter/AccountBudgetCenterInformation',
  },
  AccountFeatureSet: {
    uriPath: '/cc/API/Account/AccountFeatureSet',
    primaryKey: 'accountId',
  },
  AccountFeatureEnabled: {
    uriPath: '/cc/API/Account/AccountFeatureEnabled',
    primaryKey: 'pilot',
  },
  AccountsFetchUnderTheSameABL: {
    uriPath: '/cc/API/Account/FetchAccountsUnderTheSameABL',
  },
  AccountIOAutoApproval: {
    uriPath: '/cc/InsertionOrder/AccountIOAutoApproval',
    primaryKey: 'AccountId',
  },
  AccountIOForecastingDailySpend: {
    uriPath: '/cc/API/InsertionOrder/InsertionOrderForecastingDailySpend',
  },
  AccountsInfoForAgency: {
    uriPath: '/cc/Agency/AccountsInfoForAgency',
    primaryKey: 'AccountId',
  },
  AccountAddOnBehalfOfAgencyContext: {
    uriPath: '/cc/Customers/AccountAddOnBehalfOfAgencyContext',
    primaryKey: 'AccountId',
  },
  AccountFundingInfo: {
    uriPath: '/cc/API/PaymentUtility/AccountFundingInfo',
    primaryKey: 'AccountId',
  },
  AccountIdentityVerification: {
    uriPath: '/cc/API/AccountIdentityVerification',
    primaryKey: 'accountId',
  },
  AccountIdentityVerificationInfo: {
    uriPath: '/cc/API/AccountIdentityVerification/Info',
    primaryKey: 'accountId',
  },
  AccountIdentityVerificationOnfidoToken: {
    uriPath: '/cc/API/AccountIdentityVerification/OnfidoToken',
    primaryKey: 'accountId',
  },
  AccountIdentityVerificationOnfidoNameComparison: {
    uriPath: '/cc/API/AccountIdentityVerification/OnfidoNameComparison',
  },
  AccountIdentityVerificationRetriggerBusinessVerification: {
    uriPath: '/cc/API/AccountIdentityVerification/RetriggerBusinessVerification',
  },
  AccountIdentityVerificationStateOverride: {
    uriPath: '/cc/API/AccountIdentityVerification/StateOverride',
  },
  AccountIdentityVerificationHoldOverride: {
    uriPath: '/cc/API/AccountIdentityVerification/HoldOverride',
  },
  AccountIdentityVerificationAppeal: {
    uriPath: '/cc/API/AccountIdentityVerification/Appeal',
    primaryKey: 'accountId',
  },
  AccountIdentityVerificationAppealDecision: {
    uriPath: '/cc/API/AccountIdentityVerification/AppealDecision',
  },
  AccountLevelAPContact: {
    uriPath: '/cc/API/Billing/AccountLevelAPContact',
    primaryKey: 'cid',
  },
  AccountMap: {
    uriPath: '/cc/API/Customer/AccountMap',
    primaryKey: 'rcid',
  },
  // TODO: Used in Create Account Step 3. This API should be split up and the server logic moved to client-side.
  AccountPaymentInformation: {
    uriPath: '/cc/Account/PaymentInformation',
  },
  AccountReparentingJobLoad: {
    uriPath: '/cc/API/Reparenting/JobLoad',
  },
  AccountReparentingLoadAccounts: {
    uriPath: '/cc/API/Reparenting/LoadAccounts',
  },
  AccountReparentingJobSubmit: {
    uriPath: '/cc/API/Reparenting/JobSubmit',
  },
  AccountReparentingJobAdd: {
    uriPath: '/cc/API/Reparenting/JobAdd',
  },
  AccountReparentingJobUpdate: {
    uriPath: '/cc/API/Reparenting/JobUpdate',
  },
  AccountReparentingValidateAccounts: {
    uriPath: '/cc/API/Reparenting/ValidateAccounts',
  },
  AccountReparentingValidateTargetCustomer: {
    uriPath: '/cc/API/Reparenting/ValidateTargetCustomer',
  },
  AccountReparentingValidateLinkInfo: {
    uriPath: '/cc/API/Reparenting/ValidateLinkInfo',
  },
  AccountReparentingValidateBillingInfo: {
    uriPath: '/cc/API/Reparenting/ValidateBillingInfo',
  },
  AccountReparentingFullValidate: {
    uriPath: '/cc/API/Reparenting/FullValidate',
  },
  ActivateAPContact: {
    uriPath: '/cc/ActivateAPContact',
    primaryKey: 'code',
  },
  AddressLoadCountry: {
    uriPath: '/cc/Address/LoadCountry',
  },
  AdjustmentDocument: {
    uriPath: '/cc/Adjustments/AdjustmentDocument',
    primaryKey: 'DocumentId',
  },
  AdjustmentNewAdjustment: {
    uriPath: '/cc/Adjustments/NewAdjustment',
    primaryKey: 'Id',
  },
  AgencyBulkLinkJobUpdate: {
    uriPath: '/cc/Agency/AgencyBulkLinkJobUpdate',
    primaryKey: 'cid',
  },
  AgencyLinkRequest: {
    uriPath: '/cc/API/Linking/AgencyLinkRequest',
  },
  AgencyRequest: {
    uriPath: '/cc/Agency/Requests',
  },
  AgencyRequestBulkAction: {
    uriPath: '/cc/Agency/BulkAction',
    primaryKey: 'cid',
  },
  AgencyLinkedCustomers: {
    uriPath: '/cc/Customers/AgencyLinkedCustomers',
  },
  AncestorHierarchyPath: {
    uriPath: '/cc/Customers/AncestorHierarchyPath',
  },
  AssociateAndRedeemCoupon: {
    uriPath: '/cc/API/Coupon/smartautocoupon',
  },
  AssociateCoupon: {
    uriPath: '/cc/API/Coupon/AssociateCoupon',
  },
  BAARecommendations: {
    uriPath: '/cc/API/Recommendations/BAARecommendations',
  },
  Balance: {
    uriPath: '/cc/API/Billing/Balance',
    primaryKey: 'AccountId',
  },
  BillingGroup: {
    uriPath: '/cc/API/BillingGroup',
  },
  BillingGroupAccount: {
    uriPath: '/cc/API/BillingGroup/BillingGroupAccount',
    primaryKey: 'AccountId',
  },
  BillingSPABatchDownload: {
    uriPath: '/cc/Billing/SPABatchDownload',
  },
  BillingStatements: {
    uriPath: '/cc/API/Billing/BillingStatements',
    primaryKey: 'BillingDocumentId',
  },
  BillingSummary: {
    uriPath: '/cc/API/Billing/BillingSummary',
  },
  BillingSummaryForAccounts: {
    uriPath: '/cc/API/Billing/BillingSummaryForAccount',
  },
  BillingSettings: {
    uriPath: '/cc/Billing/BillingSettings',
    primaryKey: 'cid',
  },
  BillingSettingsUser: {
    uriPath: '/cc/Billing/BillingSettingsUser',
    primaryKey: 'cid',
  },
  BillingSettingsAddress: {
    uriPath: '/cc/Billing/BillingSettingsAddress',
    primaryKey: 'Id',
  },
  BillingSettingsUpdate: {
    uriPath: '/cc/Billing/BillingSettingsUpdate',
  },
  /* This BillingTile is restricted to be only used in BillingGrid SPA feature.
    After move billing tile to SPA this part will be removed.
  */
  BillingTile: {
    uriPath: '/cc/Billing/BillingTile',
  },
  BillNow: {
    uriPath: '/cc/API/Billing/BillNow',
  },
  BillNowForMca: {
    uriPath: '/cc/API/Billing/BillNowForMca',
  },
  BudgetInsightsAndAlerts: {
    uriPath: '/cc/API/BudgetCenter/BudgetInsightsAndAlerts',
  },
  BulkCreateInsertionOrderViewData: {
    uriPath: '/cc/InsertionOrder/BulkCreateInsertionOrderViewData',
    primaryKey: 'CustomerId',
  },
  BulkCreateInsertionOrder: {
    uriPath: '/cc/insertionOrder/CreateInsertionOrders',
  },
  BulkCustomerLinkJob: {
    uriPath: '/cc/Agency/BulkCustomerLinkJob',
    primaryKey: 'cid',
  },
  BulkEstimateTaxes: {
    uriPath: '/cc/API/Billing/BulkEstimateTaxes',
    primaryKey: 'AccountId',
  },
  BulkGetPrimaryContacts: {
    uriPath: '/cc/accounts/BulkGetPrimaryContacts',
  },
  BulkLinkingCustomerInfo: {
    uriPath: '/cc/Agency/BulkLinkingCustomerInfo',
    primaryKey: 'cid',
  },
  BulkLinkJob: {
    uriPath: '/cc/Agency/BulkLinkJob',
    primaryKey: 'cid',
  },
  BulkLinkJobUpdate: {
    uriPath: '/cc/Agency/BulkLinkJobUpdate',
    primaryKey: 'cid',
  },
  BulkPrepayPaymentParams: {
    uriPath: '/cc/Payment/BulkPrepayPaymentParams',
    primaryKey: 'cid',
  },
  BulkUpdatePrimaryContacts: {
    uriPath: '/cc/account/BulkUpdatePrimaryContacts',
  },
  BusinessAddressLoadCountry: {
    uriPath: '/cc/BusinessAddress/LoadCountryData', // This endpoint is deprecated. Please use DomainDataCountry
  },
  CampaignBudgetCenterInformation: {
    uriPath: '/cc/API/BudgetCenter/CampaignBudgetCenterInformation',
  },
  CampaignBudgetSummaryGrid: {
    uriPath: '/cc/API/BudgetCenter/CampaignBudgetSummaryGrid',
    primaryKey: 'CampaignId',
  },
  CampaignPerformanceBudget: {
    uriPath: '/cc/API/BudgetCenter/CampaignPerformanceBudget',
    primaryKey: 'CampaignId',
  },
  CanTriggerFCAVerification: {
    uriPath: '/cc/API/UKFCAVerification/CanTriggerFCAVerification',
    primaryKey: 'accountId',
  },
  CheckBillingGroupNameAvailability: {
    uriPath: '/cc/API/BillingGroup/CheckBillingGroupNameAvailability',
  },
  CleanDomain: {
    uriPath: '/cc/API/UKFCAVerification/CleanDomain',
    primaryKey: 'RawDomain',
  },
  ClearBalance: {
    uriPath: '/ClearBalance/ClearAccountBalance',
    primaryKey: 'accountId',
    isSupportUIAPI: true,
  },
  CountryCurrenciesMapping: {
    uriPath: '/cc/API/Currency/CountryCurrenciesMapping',
    primaryKey: 'country',
  },
  CountryTaxTypesMapping: {
    uriPath: '/cc/API/Country/CountryTaxTypesMapping',
  },
  CountryMapping: {
    uriPath: '/cc/API/Country',
  },
  Coupon: {
    uriPath: '/cc/API/Coupon',
  },
  CountryTimeZonesMapping: {
    uriPath: '/cc/API/TimeZone/GetTimeZonesPerCountry',
  },
  // For CCUI
  CouponRedemptionsAndAssociations: {
    uriPath: '/cc/API/Coupon/CouponRedemptionsAndAssociations',
    primaryKey: 'accountId',
  },
  ChannelPartnerPaymentContext: {
    uriPath: '/cc/API/PaymentInstrument/ChannelPartnerPaymentContext',
  },
  CNAccountVerification: {
    uriPath: '/cc/API/CNVerification/AccountVerification',
    primaryKey: 'id',
  },
  CNAccountVerificationSummary: {
    uriPath: '/cc/API/CNVerification/AccountVerificationSummary',
    primaryKey: 'accountId',
  },
  CNPredicateAccountVerificationStatus: {
    uriPath: '/cc/API/CNVerification/PredicateAccountVerificationStatus',
    primaryKey: 'accountId',
  },
  CNLackedQualifications: {
    uriPath: '/cc/API/CNVerification/LackedQualifications',
    primaryKey: 'accountId',
  },
  CNVerificationGridData: {
    uriPath: '/cc/API/CNVerification/QualificationList',
    primaryKey: 'QualificationId',
  },
  CNVerificationIndustryList: {
    uriPath: '/cc/API/CNVerification/IndustryList',
    primaryKey: 'aid',
  },
  CNVerificationQualification: {
    uriPath: '/cc/API/CNVerification/Qualification',
    primaryKey: 'QualificationId',
  },
  CreateCustomer: {
    uriPath: '/cc/Customers/CreateCustomer',
  },
  // This is the API used for create account. It returns currencies based on CID + Country Code.
  // The primary key is currently a string like 'query-71-0'. It should probably be changed to currencyId.
  Currencies: {
    uriPath: '/cc/Account/Currencies',
    primaryKey: 'Id',
  },
  // This is the API used by OBO. It returns currencies based on Country Code only.
  // It can probably be merged with Currencies (e.g. "Currency.findAll({ countryCode: 'US' })").
  CurrenciesByCountry: {
    uriPath: '/cc/Account/CurrenciesByCountry',
    primaryKey: 'Id',
  },
  // Not sure if this API is used. It is an extended version of Currencies.
  // It returns a list of currencies with their allowed countries, by CID.
  CurrencyCountriesMapping: {
    uriPath: '/cc/API/Currency/CurrencyCountriesMapping',
    primaryKey: 'currency',
  },
  CurrencyExchangeRate: {
    uriPath: '/cc/API/Currency/ExchangeRate',
  },
  Customer: {
    uriPath: '/cc/API/Customer',
  },
  CustomerAccess: {
    uriPath: '/cc/Customers/CustomerAccess',
    primaryKey: 'UserId',
  },
  CustomerAccountSignupOnBehalfOf: {
    uriPath: '/cc/Customers/CustomerAccountSignupOnBehalfOf',
    primaryKey: 'CustomerId',
  },
  CustomerAccountSignupOnBehalfOfAgencyContext: {
    uriPath: '/cc/Customers/CustomerAccountSignupOnBehalfOfAgencyContext',
    primaryKey: 'CustomerId',
  },
  CustomerAddress: {
    uriPath: '/cc/API/Customer/Address',
    primaryKey: 'Id',
  },
  CustomerAddresses: {
    uriPath: '/cc/BusinessAddress/CustomerAddresses', // This endpoint is deprecated. Please use CustomerAddress
  },
  CustomerFeatureDetail: {
    uriPath: '/cc/API/Customer/CustomerFeatureDetail',
    primaryKey: 'customerId',
  },
  CustomerFeatureEnabled: {
    uriPath: '/cc/API/Customer/CustomerFeatureEnabled',
    primaryKey: 'customerId',
  },
  CustomerFeatureEnabledSet: {
    uriPath: '/cc/API/Customer/CustomerFeatureEnabledSet',
    primaryKey: 'customerId',
  },
  CustomerHierarchy: {
    uriPath: '/cc/Customers/SearchCustomerHierarchy',
  },
  CustomerInfo: {
    uriPath: '/cc/Customers/CustomerInfo',
    primaryKey: 'Id',
  },
  CustomerLinkPermission: {
    uriPath: '/cc/Agency/CustomerLinkPermissionUpdate',
    primaryKey: 'cid',
  },
  CustomersManagement: {
    uriPath: '/cc/Customers/Management',
  },
  CustomerProfile: {
    uriPath: '/cc/API/Customer/Profile',
    primaryKey: 'CustomerId',
  },
  DailyRemainingCreditLimit: {
    uriPath: '/cc/API/Account/DailyRemainingCreditLimit',
  },
  DeleteCtpPI: {
    uriPath: '/cc/API/PaymentInstrument/DeleteCtpPI',
  },
  DeletePI: {
    uriPath: '/cc/API/PaymentInstrument/DeletePI',
  },
  DeleteFCAVerification: {
    uriPath: '/cc/API/UKFCAVerification/DeleteFCAVerification',
    primaryKey: 'AccountId',
  },
  DelinkAccountRequest: {
    uriPath: '/cc/agency/CreateDelinkRequest',
    primaryKey: 'Message',
  },
  DomainDataAdjustmentReason: {
    uriPath: '/cc/API/DomainData/AdjustmentReasonMapping',
  },
  DomainDataCountry: {
    uriPath: '/cc/API/DomainData/CountryMapping',
    primaryKey: 'Id',
  },
  // This is just a list of currency information. No CID filtering included.
  DomainDataCurrency: {
    uriPath: '/cc/API/DomainData/CurrencyMapping',
    primaryKey: 'Id',
  },
  DomainDataEmailFreeHostDomain: {
    uriPath: '/cc/API/DomainData/EmailFreeHostDomain',
  },
  DomainDataIndustry: {
    uriPath: '/cc/API/DomainData/IndustryMapping',
    primaryKey: 'Code',
  },
  DomainDataLanguage: {
    uriPath: '/cc/API/DomainData/LanguageMapping',
    primaryKey: 'Code',
  },
  DomainDataBillingLanguage: {
    uriPath: '/cc/API/DomainData/BillingLanguageMapping',
    primaryKey: 'Key',
  },
  DomainDataGeography: {
    uriPath: '/cc/API/DomainData/LocalizedMappingCountry',
    primaryKey: 'Id',
  },
  DomainDataSubGeography: {
    uriPath: '/cc/API/DomainData/LocalizedMappingSubGeography',
  },
  DomainDataTimeZone: {
    uriPath: '/cc/API/DomainData/TimeZoneMapping',
    primaryKey: 'Name',
  },
  DomainDataCountryCodeCompanyCode: {
    uriPath: '/cc/API/DomainData/CountryCodeCompanyCodeMapping',
    primaryKey: 'Key',
  },
  EditUserViewModel: {
    uriPath: '/cc/API/User/EditUserViewModel',
    primaryKey: 'tuid',
  },
  EditUser: {
    uriPath: '/cc/API/User/EditUser',
  },
  EmailTokenValidation: {
    uriPath: '/cc/API/EmailTokenValidation',
    primaryKey: 'RequestId',
  },
  EstimateTaxes: {
    uriPath: '/cc/API/Billing/EstimateTaxes', // This endpoint is deprecated. Please use EstimateTaxesAllPayments
  },
  EstimateTaxesAllPayments: {
    uriPath: '/cc/API/Billing/EstimateTaxesAllPayments',
  },
  ExpertAccount: {
    uriPath: '/cc/API/Account/ExpertAccount',
    primaryKey: 'AccountId',
  },
  ExportAccountTransferSummary: {
    uriPath: '/cc/API/Reparenting/RenderAccountTransferSummaryExcel',
  },
  // The primary key is currently a string like '<accountId>-<featureId>'
  // This is so it can be fetched without knowing the current promotionId
  // account Id is included in the key because different accounts could have different promotions for a feature
  FeatureAdoptionCoupon: {
    uriPath: '/cc/API/Coupon/FeatureAdoptionCouponAdoptions',
    primaryKey: 'id',
  },
  FraudCustomerInfo: {
    uriPath: '/cc/API/FraudAppeal/FraudCustomerInfo',
  },
  FraudCustomerOptInAiv: {
    uriPath: '/cc/API/FraudAppeal/FraudCustomerOptInAiv',
  },
  FraudCustomerVerificationInfo: {
    uriPath: '/cc/API/FraudAppeal/FraudCustomerVerificationInfo',
  },
  GetCouponDetails: {
    uriPath: '/cc/API/Coupon/GetCouponDetails',
  },
  // For MCA
  GetCouponRedemptionsAndAssociations: {
    uriPath: '/cc/API/Coupon/GetCouponRedemptionsAndAssociations',
  },
  GetCouponStringForSignup: {
    uriPath: '/cc/API/Coupon/GetCouponStringForSignup',
  },
  GetCouponValidForCustomerWebsite: {
    uriPath: '/cc/API/Coupon/GetCouponValidForCustomerWebsite',
    primaryKey: 'websiteURL',
  },
  GetCustomers: {
    uriPath: '/cc/Customers/GetCustomers',
    primaryKey: 'userId',
  },
  GetPrimaryContacts: {
    uriPath: '/cc/API/User/PrimaryContacts',
  },
  HierarchyCustomersAccounts: {
    uriPath: '/cc/API/Hierarchy',
  },
  HierarchyLinkRequest: {
    uriPath: '/cc/API/Linking/HierarchyLinkRequest',
  },
  IOImportHistory: {
    uriPath: '/cc/InsertionOrder/IOImportHistory',
    primaryKey: 'ImportId',
  },
  IOImportSummary: {
    uriPath: '/cc/InsertionOrder/ImportSummary',
    primaryKey: 'entities',
  },
  IOImportApplyOrCancel: {
    uriPath: '/cc/InsertionOrder/ApplyOrCancelBulkIOImport',
    primaryKey: 'ImportId',
  },
  IndiaEInvoicingGenerationValidation: {
    uriPath: '/cc/API/Account/ValidateIndiaEInvoicingGeneration',
  },
  InsertionOrdersGridData: {
    uriPath: '/cc/InsertionOrder/InsertionOrdersGridData',
    primaryKey: 'InsertionOrderId',
  },
  InsertionOrdersGridColumnChooserData: {
    uriPath: '/cc/InsertionOrder/InsertionOrdersGridColumnChooserData',
  },
  InsertionOrdersGridFilterData: {
    uriPath: '/cc/InsertionOrder/InsertionOrdersGridFilterData',
  },
  InsertionOrdersMenuData: {
    uriPath: '/cc/InsertionOrder/InsertionOrdersMenuData',
  },
  InsertionOrderPermission: {
    uriPath: '/cc/API/InsertionOrder/InsertionOrderPermission',
  },
  InsertionOrder: {
    uriPath: '/cc/API/InsertionOrder',
  },
  InviteUser: {
    uriPath: '/cc/API/User/InviteUser',
  },
  LoadBillingGroupCandidates: {
    uriPath: '/cc/API/BillingGroup/LoadBillingGroupCandidates',
  },
  LoadUngroupedAccounts: {
    uriPath: '/cc/API/BillingGroup/LoadUngroupedAccounts',
  },
  LinkRequest: {
    uriPath: '/cc/API/Linking/LinkRequest',
  },
  ManagersModel: {
    uriPath: '/cc/API/Linking/Managers',
    primaryKey: 'cid',
  },
  MandateAgreementRequired: {
    uriPath: '/cc/API/MandateAgreementRequired',
    primaryKey: 'customerId',
  },
  McaPaymentContexts: {
    uriPath: '/cc/API/PaymentInstrument/McaPaymentContext',
  },
  MultiUserAuthorize: {
    uriPath: '/cc/API/User/MultiUserAuthorize',
    primaryKey: 'cid',
  },
  MultiUserCustomer: {
    uriPath: '/cc/Customers/MultiUserCustomer',
  },
  NavbarTab: {
    uriPath: '/cc/Tabs/NavbarTab',
  },
  OfflinePaymentInstructions: {
    uriPath: '/cc/Payment/OfflinePaymentInstructions',
  },
  PaymentInstrument: {
    uriPath: '/cc/API/PaymentInstrument',
    primaryKey: 'Id',
  },
  PaymentOptions: {
    uriPath: '/cc/API/PaymentInstrument/PaymentOptions',
  },
  PaymentSettingSwitch: {
    uriPath: '/cc/API/PaymentSettingSwitch',
  },
  PendingBillToChangeLight: {
    uriPath: '/cc/API/Billing/PendingBillToChangeLight',
    primaryKey: 'AccountId',
  },
  PermissionAccount: {
    uriPath: '/cc/API/Permission/Account',
  },
  PermissionCustomer: {
    uriPath: '/cc/API/Permission/Customer',
  },
  Person: {
    uriPath: '/cc/Customers/Person',
    primaryKey: 'PersonId',
  },
  PersonHistory: {
    uriPath: '/cc/Customers/PersonHistory',
  },
  PilotFeatures: {
    uriPath: '/cc/API/Customer/PilotFeatures',
  },
  PotentialBillingGroups: {
    uriPath: '/cc/API/BillingGroup/PotentialBillingGroups',
  },
  PrimaryContacts: {
    uriPath: '/cc/Users/PrimaryContacts',
  },
  Rebill: {
    uriPath: '/cc/API/Rebill',
  },
  RebillDocumentInfo: {
    uriPath: '/cc/Rebill/DocumentInfo',
    primaryKey: 'DocumentId',
  },
  Recommendation: {
    uriPath: '/cc/API/Notifications/Recommendation',
  },
  RecurringInsertionOrder: {
    uriPath: '/cc/InsertionOrder/RecurringInsertionOrder',
    primaryKey: 'SeriesId',
  },
  RecurringInsertionOrderViewData: {
    uriPath: '/cc/InsertionOrder/RecurringInsertionOrderViewData',
    primaryKey: 'CustomerId',
  },
  RequestDetailsModel: {
    uriPath: '/cc/Agency/RequestDetailsModel',
    primaryKey: 'id',
  },
  RequiredTopUpAmount: {
    uriPath: '/cc/API/PaymentUtility/RequiredTopUpAmount',
    primaryKey: 'accountId',
  },
  ResendAPContactValidation: {
    uriPath: '/cc/Billing/ResendAPContactValidation',
  },
  RiskControl: {
    uriPath: '/cc/API/PaymentUtility/RiskControl',
    primaryKey: 'AccountId',
  },
  SAPApplication: {
    uriPath: '/cc/API/PaymentInstrument/SAPApplication',
    primaryKey: 'SAPApplicationId',
  },
  SAPApplicationLight: {
    uriPath: '/cc/API/PaymentInstrument/SAPApplicationLight',
    primaryKey: 'SAPApplicationId',
  },
  SAPApplicationByCustomerId: {
    uriPath: '/cc/API/PaymentInstrument/SAPApplicationByCustomerId',
    primaryKey: 'SAPApplicationId',
  },
  SAPCreditLimit: {
    uriPath: '/cc/API/PaymentInstrument/SAPCreditLimit',
    primaryKey: 'SAPId',
  },
  SAPHighestMonthlySpend: {
    uriPath: '/cc/API/PaymentInstrument/SAPHighestMonthlySpend',
    primaryKey: 'SAPId',
  },
  SAPLightInfo: {
    uriPath: '/cc/API/PaymentInstrument/SAPLightInfo',
    primaryKey: 'invoiceId',
  },
  SaveCouponStringForSignup: {
    uriPath: '/cc/API/Coupon/SaveCouponStringForSignup',
    primaryKey: 'couponString',
  },
  SearchAccountMap: {
    uriPath: '/cc/API/Customer/SearchAccountMap',
  },
  ServiceLevel: {
    uriPath: '/cc/API/Customer/ServiceLevel',
  },
  SetFCADomainStatus: {
    uriPath: '/cc/API/UKFCAVerification/SetFCADomainStatus',
  },
  SettingsEmailNotification: {
    uriPath: '/cc/Settings/EmailNotificationSettings',
    primaryKey: 'cid',
  },
  SettingsEmailNotificationCategory: {
    uriPath: '/cc/Settings/EmailNotificationCategorySettings',
    primaryKey: 'tuid',
  },
  SettingsUserPreference: {
    uriPath: '/cc/Settings/UserPreferences',
    primaryKey: 'AccountId',
  },
  ShowLightBox: {
    uriPath: '/cc/Notifications/ShowLightBox',
    primaryKey: 'cid',
  },
  SignupAggreementOptions: {
    uriPath: '/Signup/AggreementOptions',
    primaryKey: 'countryCode',
  },
  SmartSignupAggreementOptions: {
    uriPath: '/Smart/Signup/AggreementOptions',
    primaryKey: 'countryCode',
  },
  SmartAccountStatus: {
    uriPath: '/cc/API/Customer/SmartAccountStatus',
    primaryKey: 'customerId',
  },
  SPAGetPaymentStatusAlerts: {
    uriPath: '/cc/Billing/SPAGetPaymentStatusAlerts',
    primaryKey: 'accountError',
  },
  StatusNotification: {
    uriPath: '/cc/Notifications/StatusNotifications',
  },
  SubmitBulkUnlinkAccounts: {
    uriPath: '/cc/managers/SubmitBulkUnlinkAccounts',
    primaryKey: 'ResultType',
  },
  SwitchPaymentSetting: {
    uriPath: '/BulkPaymentSettingSwitch/SwitchPaymentSetting',
    primaryKey: 'accountId',
    isSupportUIAPI: true,
  },
  TopUp: {
    uriPath: '/cc/Payment/TopUp',
  },
  TopUpLimit: {
    uriPath: '/cc/API/PaymentUtility/TopUpLimit',
  },
  TradeScreeningAccountEntity: {
    uriPath: '/cc/API/TradeScreeningTransformation/GridDataAccountEntity',
    primaryKey: 'Id',
  },
  TradeScreeningCustomerEntity: {
    uriPath: '/cc/API/TradeScreeningTransformation/GridDataCustomerEntity',
    primaryKey: 'Id',
  },
  TradeScreeningUserEntity: {
    uriPath: '/cc/API/TradeScreeningTransformation/GridDataUserEntity',
    primaryKey: 'Id',
  },
  TransactionHistory: {
    uriPath: '/cc/API/Billing/TransactionHistory',
  },
  UCPOfflineRefund: {
    uriPath: '/cc/API/UCPOfflineRefund',
  },
  UKFCAVerification: {
    uriPath: '/cc/API/UKFCAVerification',
    primaryKey: 'AccountId',
  },
  UnifiedQueueNotification: {
    uriPath: '/cc/API/Notifications/UnifiedQueueNotification',
  },
  UnifiedSmartAccountStatus: {
    uriPath: '/cc/API/Customer/UnifiedSmartAccountStatus',
  },
  UpdateAccountsInBillingGroup: {
    uriPath: '/cc/BillingGroup/UpdateAccountsInBillingGroup',
  },
  User: {
    uriPath: '/cc/API/User',
  },
  UserRole: {
    uriPath: '/cc/API/User/UserRole',
  },
  UserPreference: {
    uriPath: '/cc/API/Preference/UserPreference',
    primaryKey: 'upkey',
  },
  ValidateCustomerNumbersForLinking: {
    uriPath: '/cc/Agency/ValidateCustomerNumbersForLinking',
    primaryKey: 'CustomerId',
  },
  ValidateXandrBafMembers: {
    uriPath: '/cc/API/XandrBaf/ValidateMembers',
  },
  VerifyBusinessAddress: {
    uriPath: '/cc/API/Address/VerifyBusinessAddress',
  },
  VerifyCustomerSpendRequirement: {
    uriPath: '/cc/API/Customer/VerifyCustomerSpendRequirement',
  },
  VerifyEmailTokenValidation: {
    uriPath: '/cc/API/EmailTokenValidation/VerifyEmailTokenValidation',
    primaryKey: 'accountId',
  },
  VerifySAPCustomerUniqueness: {
    uriPath: '/cc/API/PaymentInstrument/VerifySAPCustomerUniqueness',
  },
  VerifyTaxInformation: {
    uriPath: '/cc/API/Address/VerifyTaxInformation',
  },
  XandrAccount: {
    uriPath: '/cc/API/Account/XandrAccount',
  },
  XandrBaf: {
    uriPath: '/cc/API/XandrBaf',
    primaryKey: 'BAFId',
  },
  XandrBafMembers: {
    uriPath: '/cc/API/XandrBaf/BafMembers',
    primaryKey: 'BAFId',
  },
  XandrBafChangeRecord: {
    uriPath: '/cc/API/XandrBaf/ChangeRecord',
    primaryKey: 'BAFId',
  },
  YahooToken: {
    uriPath: '/TokenExchange/Yahoo',
    primaryKey: 'token',
  },
};
